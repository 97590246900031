import "./../stylesheets/Trending.css";
import api from "../../utils/api";
import play from "./../../images/play_button.svg";
import add from "./../../images/add.svg";
import download from "./../../images/download.svg";
import more from "./../../images/more.svg";
import left from "./../../images/left.svg";
import right from "./../../images/right.svg";
import { useRef, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import Popup from "reactjs-popup";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AddToPlaylistPopup from "../addToPlaylistPopup";
import SharePopup from "./../sharePopup";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImgLoader from "./../imgLoader";
import BuyPopup from "./../buyPopup";
import { useNavigation } from "../../context/navigationContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useCurrencyContext } from "../../context/currencyContext";
import { usePlayerContext } from "../../context/playerContext";
import { addToQueue, playNow } from "../../actions/playerActions";

function TrendingSongs({
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  const { navigateToMusic, navigateToComments, navigateToArtists, navigate } =
    useNavigation();
  const { isAuthenticated } = useAuthContext();
  const { formatCurrency } = useCurrencyContext();

  const [data, setData] = useState(null);

  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const [isAddToPlaylistPopupOpen, setIsAddToPlaylistPopupOpen] =
    useState(false);
  const [addPopupBeat, setAddPopupBeat] = useState();

  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [sharePopupBeat, setSharePopupBeat] = useState();
  const [buyPopupBeat, setBuyPopupBeat] = useState(null);
  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);
  const handleComment = (event) => {
    const id = event.target.dataset.id;
    navigateToComments("music", id);
  };
  const handleProducerClick = (event) => {
    const id = event.target.dataset.id;
    navigateToArtists(id);
  };
  const handleDownload = async (event) => {
    if (isAuthenticated) {
      const id = event.target.closest(".music-card").dataset.id;

      try {
        const response = await api.get(`beats/${id}/download`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        window.location.href = data;
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      navigate("/login");
    }
  };

  const { playerState, playerDispatch } = usePlayerContext();

  const handlePlayItem = (item) => {
    playerDispatch(playNow(item));
  };

  const handleAddToQueue = (item) => {
    playerDispatch(addToQueue(item));
  };

  const handleAddToCart = async (id, price) => {
    if (isAuthenticated) {
      const response = await handleCart(id, "song", price);
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response?.error) {
        setPopupMessage(response?.error?.error);
        setShowMessagePopup(true);
        return;
      }
      setUpdateCartCounter(updateCartCounter + 1);
      setPopupMessage("Added to cart");
      setShowMessagePopup(true);
    } else {
      const desiredObject = data.find((obj) => obj.slug.toString() === id);
      const response = await handleLocalCart(desiredObject, "song", price);
      if (response === "success") {
        setUpdateCartCounter(updateCartCounter + 1);
        setPopupMessage("Added to cart");
        setShowMessagePopup(true);
      } else {
        setPopupMessage("Item already in cart!");
        setShowMessagePopup(true);
      }
    }
  };

  const handleBeatClick = (event) => {
    const beat_id = event.target.closest(".music-card").dataset.id;
    navigateToMusic(beat_id);
  };

  const handleSeeAll = () => {
    navigate("/music/all");
  };
  const responsiveness = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const beatCarouselRef = useRef(null);

  const handleBeatCarouselNext = () => {
    if (beatCarouselRef.current) {
      if (
        (beatCarouselRef.current.state.deviceType === "desktop" &&
          data.length < 5) ||
        (beatCarouselRef.current.state.deviceType === "tablet" &&
          data.length < 3) ||
        (beatCarouselRef.current.state.deviceType === "mobile" &&
          data.length < 1)
      ) {
        return;
      }
      beatCarouselRef.current.next();
    }
  };

  const handleBeatCarouselPrevious = () => {
    if (beatCarouselRef.current) {
      beatCarouselRef.current.previous();
    }
  };

  useEffect(() => {
    const getBeats = async function () {
      try {
        const response = await api.get("top/songs");

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setData(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    getBeats();
  }, [navigate, setShowUnauthenticatedPopup]);

  return (
    <>
      <section className="songs">
        <div className="heading-home">
          <h1 className="trending-tracks">Trending Songs</h1>
          <div className="arrow-keys">
            <h2 className="see__all" onClick={handleSeeAll}>
              SEE ALL
            </h2>
            <button onClick={handleBeatCarouselPrevious}>
              <img alt="" src={left}></img>
            </button>
            <button onClick={handleBeatCarouselNext}>
              <img alt="" src={right}></img>
            </button>
          </div>
        </div>

        <div className="music-cards">
          <Carousel
            ref={beatCarouselRef}
            responsive={responsiveness}
            itemClass="carousel-item"
            arrows={false}
            renderButtonGroupOutside={true}
          >
            {!data &&
              Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className="music-card beats__music__player">
                  <Skeleton className="music-card--img" />
                  <div>
                    <p className="price">
                      <Skeleton
                        count={0.35}
                        containerClassName="skeleton-loading"
                      />
                    </p>
                    <h3 className="music-card--music-name">
                      <Skeleton
                        count={0.7}
                        containerClassName="skeleton-loading"
                      />
                    </h3>
                    <div className="music-card--artist-price">
                      <Skeleton
                        count={0.45}
                        containerClassName="skeleton-loading"
                      />
                    </div>
                  </div>
                </div>
              ))}
            {data &&
              data.map((beat, index) => {
                return (
                  <div
                    className="music-card beats__music__player"
                    data-id={beat.slug}
                    key={index}
                  >
                    <div className="play_button">
                      <div className="play-div">
                        <button
                          className="play play__button__beats"
                          onClick={() => {
                            handlePlayItem(beat);
                          }}
                        >
                          <img src={play} alt="play"></img>
                        </button>
                      </div>
                      <div className="extras">
                        {beat.is_free && (
                          <button onClick={handleDownload}>
                            <img alt="" src={download}></img>
                          </button>
                        )}
                        {!beat.is_free && (
                          <div className="beats-extras__placeholder"></div>
                        )}
                        <button
                          onClick={() => {
                            handleAddToQueue(beat);
                          }}
                        >
                          <img alt="" src={add} data-id={beat.slug}></img>
                        </button>
                        <Popup
                          className="beat-rows-popup"
                          trigger={
                            <button className="more-play-beatMerge remote-tracking branch 'origin/Abd-Patch3' into Testing-ZaidDandia-Patch1">
                              <img alt="" src={more}></img>
                            </button>
                          }
                          position={["bottom left", "bottom center"]}
                          keepTooltipInside={"body"}
                          arrow={false}
                        >
                          {(close) => (
                            <>
                              <div
                                className="popup-option"
                                onClick={(event) => {
                                  close();
                                  handlePlayItem(beat);
                                }}
                                data-id={beat.slug}
                              >
                                Play
                              </div>
                              <div
                                className="popup-option"
                                onClick={(event) => {
                                  close();

                                  handleAddToCart(beat.slug);
                                }}
                                data-id={beat.slug}
                              >
                                Add To Cart
                              </div>
                              <div
                                className="popup-option"
                                onClick={(event) => {
                                  close();
                                  handleProducerClick(event);
                                }}
                                data-id={beat?.artist?.slug}
                              >
                                View Artist
                              </div>
                              <div
                                className="popup-option"
                                onClick={(event) => {
                                  close();
                                  setAddPopupBeat(beat);
                                  setIsAddToPlaylistPopupOpen(true);
                                }}
                              >
                                Add to Playlist
                              </div>
                              <div
                                className="popup-option"
                                onClick={(event) => {
                                  close();
                                  handleComment(event);
                                }}
                                data-id={beat.slug}
                              >
                                Comment
                              </div>
                              <div
                                className="popup-option"
                                onClick={() => {
                                  close();
                                  setSharePopupBeat(beat);
                                  setIsSharePopupOpen(true);
                                }}
                              >
                                Share
                              </div>
                            </>
                          )}
                        </Popup>
                      </div>
                    </div>

                    <ImgLoader
                      src={beat.cover_path}
                      className="music-card--img"
                      type="beat"
                    />
                    <div>
                      <p className="price">
                        <span
                          onClick={() => {
                            setBuyPopupBeat(beat);
                            setIsBuyPopupOpen(true);
                          }}
                        >
                          {beat.price === 0
                            ? "Free"
                            : formatCurrency(beat.price)}
                        </span>{" "}
                      </p>
                      <h3
                        className="music-card--music-name"
                        onClick={handleBeatClick}
                      >
                        {beat.name}
                      </h3>

                      <div className="music-card--artist-price">
                        <div>
                          <p
                            className="music-card-artist-name"
                            data-id={beat?.artist?.slug}
                            onClick={handleProducerClick}
                          >
                            {beat.artist.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
      </section>

      <Popup
        className={`add-queue-popup ${
          playerState.currentItemIndex !== null ? "add-queue-player-popup" : ""
        }`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>

      {isAddToPlaylistPopupOpen && (
        <AddToPlaylistPopup
          isAddToPlaylistPopupOpen={isAddToPlaylistPopupOpen}
          setIsAddToPlaylistPopupOpen={setIsAddToPlaylistPopupOpen}
          beat={addPopupBeat}
          setPopupMessage={setPopupMessage}
          setShowMessagePopup={setShowMessagePopup}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
          type={"song"}
        />
      )}
      {isSharePopupOpen && (
        <SharePopup
          isSharePopupOpen={isSharePopupOpen}
          setIsSharePopupOpen={setIsSharePopupOpen}
          beat={sharePopupBeat}
          type={"song"}
        />
      )}
      <BuyPopup
        beat={buyPopupBeat}
        setShowBuyPopup={setIsBuyPopupOpen}
        showBuyPopup={isBuyPopupOpen}
        handleAddToCart={handleAddToCart}
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
      />
    </>
  );
}

export default TrendingSongs;
