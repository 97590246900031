import "./../stylesheets/userPage.css";
import "./../../index.css";
import "./../../Fonts/fonts.css";
import userProfile from "../../images/user.png";
import FacebookLogo from "../../images/facebookLogo.svg";
import twitter from "../../images/twitter.svg";
import instagram from "../../images/instgram.svg";
import soundcloud from "../../images/soundcloud.svg";
import cross from "./../../images/cross-black.svg";
import contract from "./../../images/contract.svg";
import ProducerTracks from "./producerTracks.js";
import Musics from "./../common/musics.js";
import Albums from "./../common/albums.js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import ImgLoader from "./../imgLoader";
import * as Sentry from "@sentry/react";
import { useCurrencyContext } from "../../context/currencyContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function Producer({
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  const { navigate } = useNavigation();
  const { selectedCurrency, rates } = useCurrencyContext();
  const [producer, setProducer] = useState({});
  const [producerStatus, setProducerStatus] = useState(false);
  const [selector, setSelector] = useState("beats");

  const Id = useParams();
  useEffect(() => {
    const handleLoad = async () => {
      try {
        const response = await api.get(`producers/${Id.id}`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setProducer(data.data);
        setProducerStatus(data.data.accepting_commissions);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    handleLoad();
  }, [Id.id, navigate, setShowUnauthenticatedPopup]);
  const handleContractButton = () => {
    navigate(`/contract`, {
      state: { producerId: producer.id },
    });
  };

  function parseUrl(url) {
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    }
    return url;
  }

  return (
    <>
      {producer && (
        <section className="user-profile">
          <div
            className="profile"
            style={{
              backgroundImage: `url(${
                producer.profile_image_url === null
                  ? userProfile
                  : producer.profile_image_url
              })`,
            }}
          >
            <div className="overlay">
              <div className="user-information">
                <div className="user-image">
                  <ImgLoader
                    src={
                      producer.profile_image_url === null
                        ? userProfile
                        : producer.profile_image_url
                    }
                    className="profile-picture"
                    type="producer"
                  />
                </div>
                <div className="information">
                  <div className="name">
                    <h2>{producer.name}</h2>
                  </div>
                  <div className="tagline">
                    {producer.about &&
                      (producer.about.length > 120 ? (
                        <>
                          <p>{producer.about.substring(0, 120)}</p>
                          <Popup
                            className="create-playlist-popup"
                            trigger={<div className="read-more">Read more</div>}
                            modal
                          >
                            {(close) => (
                              <>
                                <div className="create-playlist-popup-heading">
                                  <h3>About</h3>
                                  <img src={cross} alt="" onClick={close} />
                                </div>
                                <div className="create-playlist-popup-container">
                                  <p>{producer.about}</p>
                                </div>
                              </>
                            )}
                          </Popup>
                        </>
                      ) : (
                        <p>{producer.about}</p>
                      ))}
                  </div>

                  <div className="buttons-list">
                    {producerStatus === true && (
                      <button className="hire" onClick={handleContractButton}>
                        <img alt="contract" src={contract}></img> Hire
                      </button>
                    )}
                    {producerStatus === false && (
                      <Popup
                        className="upload-error-popup hire-disabled-popup"
                        trigger={
                          <button className="hire hire-disabled">
                            <img alt="contract" src={contract}></img>{" "}
                            <div>Hire</div>
                          </button>
                        }
                        position="bottom center"
                        arrow={false}
                        on="hover"
                      >
                        This producer isn't currently accepting commission
                        requests!
                      </Popup>
                    )}
                    <div className="social-link-container">
                      <a
                        className={`social-link ${
                          producer.soundcloud_url === null ? "no-url" : ""
                        }`}
                        href={parseUrl(producer.soundcloud_url)}
                      >
                        <img src={soundcloud} alt="soundcloud"></img>
                      </a>
                      <a
                        className={`social-link ${
                          producer.twitter_url === null ? "no-url" : ""
                        }`}
                        href={parseUrl(producer.twitter_url)}
                      >
                        <img src={twitter} alt="twitter"></img>
                      </a>
                      <a
                        className={`social-link ${
                          producer.facebook_url === null ? "no-url" : ""
                        }`}
                        href={parseUrl(producer.facebook_url)}
                      >
                        <img src={FacebookLogo} alt="facebook"></img>
                      </a>
                      <a
                        className={`social-link ${
                          producer.instagram_url === null ? "no-url" : ""
                        }`}
                        href={parseUrl(producer.instagram_url)}
                      >
                        <img src={instagram} alt="instagram"></img>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navigation">
            <button
              className={`packs-button ${selector === "beats" ? "active" : ""}`}
              onClick={() => {
                if (selector !== "beats") setSelector("beats");
              }}
            >
              Beats
            </button>
            <button
              className={`packs-button ${selector === "music" ? "active" : ""}`}
              onClick={() => {
                if (selector !== "music") setSelector("music");
              }}
            >
              Music Tracks
            </button>
            <button
              className={`packs-button ${
                selector === "albums" ? "active" : ""
              }`}
              onClick={() => {
                if (selector !== "albums") setSelector("albums");
              }}
            >
              Albums
            </button>
          </div>
        </section>
      )}
      {selector === "albums" && (
        <>
          <div className="heading-albums">
            <h3 className="heading-beat-count">
              {producer.albums.length === 1
                ? `${producer.albums.length} Album`
                : `${producer.albums.length} Albums`}
            </h3>
          </div>
          <Albums
            beats={producer.albums}
            setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
            updateCartCounter={updateCartCounter}
            setUpdateCartCounter={setUpdateCartCounter}
            rates={rates}
            selectedCurrency={selectedCurrency}
            handleLocalCart={handleLocalCart}
            handleCart={handleCart}
          />
        </>
      )}
      {selector === "music" && (
        <>
          <div className="heading-albums">
            <h3 className="heading-beat-count">Singles</h3>
          </div>
          <Musics
            beats={producer.songs}
            setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
            updateCartCounter={updateCartCounter}
            setUpdateCartCounter={setUpdateCartCounter}
            rates={rates}
            selectedCurrency={selectedCurrency}
            handleLocalCart={handleLocalCart}
            handleCart={handleCart}
          />
        </>
      )}

      {selector === "beats" && producer.beats && (
        <ProducerTracks
          tracks={producer.beats}
          handleCart={handleCart}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
          setUpdateCartCounter={setUpdateCartCounter}
          updateCartCounter={updateCartCounter}
          handleLocalCart={handleLocalCart}
        />
      )}
    </>
  );
}

export default Producer;
