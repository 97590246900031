import "./../stylesheets/userPage.css";
import "./../../index.css";
import "./../../Fonts/fonts.css";
import userProfile from "../../images/user.png";
import contract from "../../images/edit.svg";
import FacebookLogo from "../../images/facebookLogo.svg";
import twitter from "../../images/twitter.svg";
import instagram from "../../images/instgram.svg";
import soundcloud from "../../images/soundcloud.svg";
import cross from "./../../images/cross-black.svg";

import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import UserBeats from "./../uploaded/userBeats";
import PurchasedBeats from "./../purchased/purchasedBeats";
import PurchasedMusic from "./../purchased/purchasedMusic";
import UploadedMusic from "./../uploaded/uploadedMusic";
import UploadedAlbums from "./../uploaded/uploadedAlbums";
import PurchasedAlbums from "./../purchased/purchasedAlbums";
import * as Sentry from "@sentry/react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function User({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const { type } = useParams();
  // const [user, setUser] = useState(null);
  const { user, isAuthenticated } = useAuthContext();

  const [seller, setSeller] = useState(false);
  const [selector, setSelector] = useState("purchased");
  const [isAcceptingCommission, setIsAcceptingCommission] = useState(
    user ? user.accepting_commissions : false
  );

  function parseUrl(url) {
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    }
    return url;
  }

  useEffect(() => {
    if (window.location.pathname.split("/")[2] === "uploads") {
      if (type === "beats") {
        setSelector("uploaded");
      } else if (type === "music") {
        setSelector("uploaded__music");
      } else if (type === "albums") {
        setSelector("uploaded__albums");
      } else {
        setSelector("uploaded");
      }
    } else if (window.location.pathname.split("/")[2] === "purchased") {
      if (type === "beats") {
        setSelector("purchased");
      } else if (type === "music") {
        setSelector("purchased__music");
      } else if (type === "albums") {
        setSelector("purchased__albums");
      } else {
        setSelector("purchased");
      }
    }
  }, [type]);
  useEffect(() => {
    const getUser = async () => {
      if (!isAuthenticated) {
        navigate("/login");
        return;
      }

      setIsAcceptingCommission(user?.accepting_commissions);
    };

    getUser();
  }, [navigate, isAuthenticated, user]);

  const handleEditProfileButton = async () => {
    navigate("/profile/edit");
  };
  const handleAcceptingCommission = async () => {
    try {
      // const response = await fetch(url, options);
      const response = await api.post("profile/accepting_commission");

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

      const data = response.data;
      if (data.success) {
        setIsAcceptingCommission(data.accepting_commissions);
        user.accepting_commissions = data.accepting_commissions;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  useEffect(() => {
    if (user) {
      setSeller(user.role === "seller" ? true : false);
      if (user.role === "seller") {
        setIsAcceptingCommission(user.accepting_commissions);
      }
    }
  }, [user]);

  return (
    <>
      {user && (
        <section className="user-profile">
          <div
            className="profile"
            style={{
              backgroundImage: `url(${
                user.profile_image_url === null
                  ? userProfile
                  : user.profile_image_url
              })`,
            }}
          >
            <div className="overlay">
              <div className="user-information">
                <div className="user-image">
                  <img
                    src={
                      user.profile_image_url === null
                        ? userProfile
                        : user.profile_image_url
                    }
                    alt="Profile"
                    className="profile-picture"
                  ></img>
                </div>
                <div className="information">
                  <div className="name">
                    <h2>{user.name}</h2>
                  </div>
                  <div className="tagline">
                    {user.about &&
                      (user.about.length > 120 ? (
                        <>
                          <p>{user.about.substring(0, 120)}</p>
                          <Popup
                            className="create-playlist-popup"
                            trigger={<div className="read-more">Read more</div>}
                            modal
                          >
                            {(close) => (
                              <>
                                <div className="create-playlist-popup-heading">
                                  <h3>About</h3>
                                  <img src={cross} alt="" onClick={close} />
                                </div>
                                <div className="create-playlist-popup-container">
                                  <p>{user.about}</p>
                                </div>
                              </>
                            )}
                          </Popup>
                        </>
                      ) : (
                        <p>{user.about}</p>
                      ))}
                  </div>
                  <div className="buttons-list">
                    <button className="hire" onClick={handleEditProfileButton}>
                      <img alt="contract" src={contract}></img> Edit Profile
                    </button>
                    {user.role === "seller" && (
                      <div className="accepting__comissions__button">
                        <h4>Accepting Commissions</h4>
                        <label className="toggle-button">
                          <input
                            type="checkbox"
                            className="accept-commission"
                            defaultChecked={isAcceptingCommission}
                            onChange={handleAcceptingCommission}
                            id="toggle"
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                    )}

                    <div className="social-link-container">
                      <a
                        className={`social-link ${
                          user.soundcloud_url === null ? "no-url" : ""
                        }`}
                        href={parseUrl(user.soundcloud_url)}
                      >
                        <img src={soundcloud} alt="soundcloud"></img>
                      </a>
                      <a
                        className={`social-link ${
                          user.twitter_url === null ? "no-url" : ""
                        }`}
                        href={parseUrl(user.twitter_url)}
                      >
                        <img src={twitter} alt="twitter"></img>
                      </a>
                      <a
                        className={`social-link ${
                          user.facebook_url === null ? "no-url" : ""
                        }`}
                        href={parseUrl(user.facebook_url)}
                      >
                        <img src={FacebookLogo} alt="facebook"></img>
                      </a>
                      <a
                        className={`social-link ${
                          user.instagram_url === null ? "no-url" : ""
                        }`}
                        href={parseUrl(user.instagram_url)}
                      >
                        <img src={instagram} alt="instagram"></img>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navigation">
            {seller && (
              <button
                className={`beats-button ${
                  selector === "uploaded" ? "active" : ""
                }`}
                onClick={() => {
                  if (selector !== "uploaded") navigate("/user/uploads/beats");
                }}
              >
                Uploaded Beats
              </button>
            )}
            {seller && (
              <button
                className={`beats-button ${
                  selector === "uploaded__music" ? "active" : ""
                }`}
                onClick={() => {
                  if (selector !== "uploaded__music")
                    navigate("/user/uploads/music");
                }}
              >
                Uploaded Tracks
              </button>
            )}
            {seller && (
              <button
                className={`beats-button ${
                  selector === "uploaded__albums" ? "active" : ""
                }`}
                onClick={() => {
                  if (selector !== "uploaded__albums")
                    navigate("/user/uploads/albums");
                }}
              >
                Uploaded Albums
              </button>
            )}
            <button
              className={`packs-button ${
                selector === "purchased" ? "active" : ""
              }`}
              onClick={() => {
                if (selector !== "purchased") navigate("user/purchased/beats");
              }}
            >
              Purchased Beats
            </button>
            <button
              className={`packs-button ${
                selector === "purchased__music" ? "active" : ""
              }`}
              onClick={() => {
                if (selector !== "purchased__music")
                  navigate("user/purchased/music");
              }}
            >
              Purchased Music
            </button>
            <button
              className={`packs-button ${
                selector === "purchased__albums" ? "active" : ""
              }`}
              onClick={() => {
                if (selector !== "purchased__albums")
                  navigate("user/purchased/albums");
              }}
            >
              Purchased Albums
            </button>
          </div>
        </section>
      )}
      {seller && selector === "uploaded" && (
        <UserBeats
          user={user}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        />
      )}
      {seller && selector === "uploaded__albums" && (
        <UploadedAlbums
          user={user}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        />
      )}
      {seller && selector === "uploaded__music" && (
        <UploadedMusic
          user={user}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        />
      )}
      {selector === "purchased" && <PurchasedBeats />}
      {selector === "purchased__albums" && <PurchasedAlbums />}
      {selector === "purchased__music" && <PurchasedMusic />}
    </>
  );
}

export default User;
