import "./../stylesheets/Trending.css";
import api from "../../utils/api";
import left from "./../../images/left.svg";
import right from "./../../images/right.svg";
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import * as Sentry from "@sentry/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImgLoader from "./../imgLoader";
import BuyPopup from "./../buyPopup";
import Popup from "reactjs-popup";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import { useAuthContext } from "../../context/authContext";
import { useCurrencyContext } from "../../context/currencyContext";
import { usePlayerContext } from "../../context/playerContext";
function TrendingAlbums({
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  const { navigate, navigateToAlbum, navigateToArtists } = useNavigation();
  const { playerState } = usePlayerContext();

  const { isAuthenticated } = useAuthContext();
  const { formatCurrency } = useCurrencyContext();
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const handleAddToCart = async (id, price) => {
    if (isAuthenticated) {
      const response = await handleCart(id, "album", price);
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response?.error) {
        setPopupMessage(response?.error?.error);
        setShowMessagePopup(true);
        return;
      }
      setUpdateCartCounter(updateCartCounter + 1);
      setPopupMessage("Added to cart");
      setShowMessagePopup(true);
    } else {
      const desiredObject = data.find((obj) => obj.slug.toString() === id);
      const response = await handleLocalCart(desiredObject, "album", price);
      if (response === "success") {
        setUpdateCartCounter(updateCartCounter + 1);
        setPopupMessage("Added to cart");
        setShowMessagePopup(true);
      } else {
        setPopupMessage("Item already in cart!");
        setShowMessagePopup(true);
      }
    }
  };
  const [data, setData] = useState(null);
  const [buyPopupBeat, setBuyPopupBeat] = useState(null);
  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);
  const handleProducerClick = (event) => {
    const id = event.target.dataset.id;
    navigateToArtists(id);
  };

  const handleBeatClick = (event) => {
    const album_id = event.target.closest(".music-card").dataset.id;
    navigateToAlbum(album_id);
  };

  const handleSeeAll = () => {
    navigate("/albums/all");
  };
  const responsiveness = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const beatCarouselRef = useRef(null);

  const handleBeatCarouselNext = () => {
    if (beatCarouselRef.current) {
      if (
        (beatCarouselRef.current.state.deviceType === "desktop" &&
          data.length < 5) ||
        (beatCarouselRef.current.state.deviceType === "tablet" &&
          data.length < 3) ||
        (beatCarouselRef.current.state.deviceType === "mobile" &&
          data.length < 1)
      ) {
        return;
      }
      beatCarouselRef.current.next();
    }
  };

  const handleBeatCarouselPrevious = () => {
    if (beatCarouselRef.current) {
      beatCarouselRef.current.previous();
    }
  };

  useEffect(() => {
    const getBeats = async function () {
      try {
        const response = await api.get("top/albums");

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setData(data.data);
      } catch (error) {
        // Sentry.captureException(error);
        // console.log(error);
      }
    };
    getBeats();
  }, [setShowUnauthenticatedPopup, navigate]);

  return (
    <>
      <section className="songs">
        <div className="heading-home">
          <h1 className="trending-tracks">Trending Albums</h1>
          <div className="arrow-keys">
            <h2 className="see__all" onClick={handleSeeAll}>
              SEE ALL
            </h2>
            <button onClick={handleBeatCarouselPrevious}>
              <img alt="" src={left}></img>
            </button>
            <button onClick={handleBeatCarouselNext}>
              <img alt="" src={right}></img>
            </button>
          </div>
        </div>

        <div className="music-cards">
          <Carousel
            ref={beatCarouselRef}
            responsive={responsiveness}
            itemClass="carousel-item"
            arrows={false}
            renderButtonGroupOutside={true}
          >
            {!data &&
              Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className="music-card beats__music__player">
                  <Skeleton className="music-card--img" />
                  <div>
                    <p className="price">
                      <Skeleton
                        count={0.35}
                        containerClassName="skeleton-loading"
                      />
                    </p>
                    <h3 className="music-card--music-name">
                      <Skeleton
                        count={0.7}
                        containerClassName="skeleton-loading"
                      />
                    </h3>
                    <div className="music-card--artist-price">
                      <Skeleton
                        count={0.45}
                        containerClassName="skeleton-loading"
                      />
                    </div>
                  </div>
                </div>
              ))}
            {data &&
              data.map((beat, index) => {
                return (
                  <div className="music-card" data-id={beat.slug} key={index}>
                    <Link to={`/albums/${beat.slug}`}>
                      <ImgLoader
                        src={beat.cover_path}
                        className="music-card--img"
                        type="beat"
                      />
                    </Link>
                    <div>
                      <p className="beats-price">
                        <span
                          onClick={() => {
                            setBuyPopupBeat(beat);
                            setIsBuyPopupOpen(true);
                          }}
                        >
                          {beat.price === 0
                            ? "Free"
                            : formatCurrency(beat.price)}
                        </span>{" "}
                        &#x2022;{" "}
                        {`${beat.tracks.length} ${
                          beat.tracks.length === 1 ? "Track" : "Tracks"
                        }`}
                      </p>
                      <h3
                        className="music-card--music-name"
                        onClick={handleBeatClick}
                      >
                        {beat.name}
                      </h3>

                      <div className="music-card--artist-price">
                        <div>
                          <p
                            className="music-card-artist-name"
                            data-id={beat?.artist?.slug}
                            onClick={handleProducerClick}
                          >
                            {beat.artist.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
      </section>
      <Popup
        className={`add-queue-popup ${
          playerState.currentItemIndex !== null ? "add-queue-player-popup" : ""
        }`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>
      <BuyPopup
        beat={buyPopupBeat}
        setShowBuyPopup={setIsBuyPopupOpen}
        showBuyPopup={isBuyPopupOpen}
        handleAddToCart={handleAddToCart}
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
      />
    </>
  );
}

export default TrendingAlbums;
