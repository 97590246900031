import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/authContext";
import { CurrencyProvider } from "./context/currencyContext";
import { Provider } from "react-redux";
import store from "./store";
import { PlayerProvider } from "./context/playerContext";

import logdna from "@logdna/browser";
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.browserProfilingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   tracePropagationTargets: ["localhost", process.env.REACT_APP_BACKEND_URL],
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

logdna.init(process.env.REACT_APP_LOGDNA_INGESTION_KEY);

logdna.addContext({
  version: "v0.1.0",
  env:
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "staging"
      ? "staging"
      : process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "production"
      ? "production"
      : "local",
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PlayerProvider>
        <CurrencyProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </CurrencyProvider>
      </PlayerProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
